










































































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import ListWidgets from "./ListWidgets.vue";
import AdvanceFilter from "./AdvanceFilter.vue";
import DoctorCard from "./DoctorCard.vue";
import DoctorService from "../../service/DoctorService";
import { IDoctorDetailsViewModel } from "../../models/DoctorDetailsViewModel";
import UniversalService from "../../../common-app/service/UniversalService";
import { IDistrict } from "../../../common-app/models/District";
import { IThana } from "../../../common-app/models/Thana";
import DoctorAppointmentService from "../../service/DoctorAppointmentService";
@Component({
  components: {
    ListWidgets,
    AdvanceFilter,
    DoctorCard,
  },
})
export default class DoctorList extends Vue {
  public doctors: IDoctorDetailsViewModel[] = [];
  public alldoctors: IDoctorDetailsViewModel[] = [];
  public isConcateSearchData = true;
  public spId: number = 0;
  public distId: number = 0;
  public currentDate: Date = new Date();
  public autocompleteList: any[] = [];
  public districts: IDistrict[] = [];
  public districtList: IDistrict[] = [];
  public specilities: any[] = [];
  public searchThanaId: string;
  public searchSpecilities: string;
  public location: any[] = [];
  public gender: number = 0;
  public fees: number = 0;
  public maxFee: number = 0;
  public consultationFee: any[] = [];
  public feesSearch: string;
  public districtName: string = "";
  public thanas: IThana[] = [];
  public orderBy: number = 0;
  public isShowLocation: boolean = true;
  rows = 10;
  currentPage = 1;
  perPage = 3;
  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newVal: any) {
    if (newVal.query.id && newVal.query.distId) {
      this.distId = Number(newVal.query.distId) || 0;
      this.spId = Number(newVal.query.id) || 0;
      DoctorService.GetSearch(this.distId, this.spId).then((res) => {
        //this.doctors = res.data;
        this.alldoctors = res.data;
        this.rows = this.alldoctors.length;
        this.paginate(this.perPage, 0);
        //console.log(this.alldoctors);
        var test = this.alldoctors.reduce((a, b) => (Number(a.fees) > Number(b.fees) ? a : b));
        this.highestFee(test.fees);
      });

      DoctorService.GetSearchForThana(this.distId, this.spId).then((res) => {
        //console.log(res.data);
        this.thanas = res.data;
      });
    } else {
      DoctorService.GetList().then((res) => {
        //this.doctors = res.data;
        this.alldoctors = res.data;
        this.alldoctors = this.alldoctors.filter((s) => s.isProfileCompleted == true);
        this.rows = this.alldoctors.length;
        this.paginate(this.perPage, 0);
        var test = this.alldoctors.reduce((a, b) => (Number(a.fees) > Number(b.fees) ? a : b));
        //console.log(this.alldoctors);
        this.highestFee(test.fees);
        //console.log(this.doctors);
      });
    }

    UniversalService.GetAddress().then((res) => {
      this.districts = res.data.districts;
      //this.districtName = this.districts.find((s) => s.id == this.distId)!.name || "";
      //this.thanas = res.data.thanas;
      //this.thanas = this.thanas.filter((s) => s.districtId === Number(this.distId));
      // console.log(this.thanas);
    });
  }
  created() {
    this.rows = 10;
    this.currentPage = 1;
    this.perPage = 10;
    this.paginate(this.perPage, 0);

    UniversalService.autoComplete(Number(1)).then((res) => {
      this.autocompleteList = res.data;
      this.autocompleteList = this.autocompleteList.filter((s) => !s.isDoctor);
    });

    UniversalService.GetAddress().then((res) => {
      this.districts = res.data.districts;
      this.districtName = this.districts.find((s) => s.id == this.distId)!.name || "";
      //this.thanas = res.data.thanas;
      //this.thanas = this.thanas.filter((s) => s.districtId === Number(this.distId));
      // console.log(this.thanas);
    });

    if (this.$route.path == "/doctor") {
      this.isShowLocation = false;
      //alert(this.isShowEdit);
    }
  }
  paginate(page_size: number, page_number: number) {
    this.doctors = this.alldoctors.slice(page_number * page_size, (page_number + 1) * page_size);
  }
  onPageChanged(page: number) {
    this.paginate(this.perPage, page - 1);
  }
  handelSearch(data: any) {
    if (this.isConcateSearchData) {
      this.doctors = [];
    }
    if (data.type == 1) {
      if (data.isChecked) {
        DoctorService.getDoctorSearch(data.type, data.searchKey, this.distId, this.spId).then((res) => {
          this.doctors = this.getUnique(this.doctors.concat(res.data), "id");
        });
      } else {
        this.doctors = this.doctors.filter((s) => s.searchKey != data.searchKey);
      }
    }
    // time event
    if (data.type == 2) {
      DoctorService.getDoctorSearch(data.type, data.searchKey, this.distId, this.spId).then((res) => {
        this.doctors = this.doctors.filter((s) => s.type != data.type);
        this.doctors = this.getUnique(this.doctors.concat(res.data), "id");
      });
    }
    // fee event
    if (data.type == 3) {
      DoctorService.getDoctorSearch(data.type, data.searchKey, this.distId, this.spId).then((res) => {
        this.doctors = this.doctors.filter((s) => s.type != data.type);
        this.doctors = this.getUnique(this.doctors.concat(res.data), "id");
      });
    }
    if (data.type == 4) {
      DoctorService.getDoctorSearch(data.type, data.searchKey, this.distId, this.spId).then((res) => {
        this.doctors = this.doctors.filter((s) => s.type != data.type);
        this.doctors = this.getUnique(this.doctors.concat(res.data), "id");
      });
    }
    if (data.type == 5) {
      DoctorService.getDoctorSearch(data.type, data.searchKey, this.distId, this.spId).then((res) => {
        this.doctors = this.doctors.filter((s) => s.type != data.type);
        this.doctors = this.getUnique(this.doctors.concat(res.data), "id");
      });
    }
    if (data.type == 6) {
      DoctorService.getDoctorSearch(data.type, data.searchKey, this.distId, this.spId).then((res) => {
        this.doctors = this.doctors.filter((s) => s.type != data.type);
        this.doctors = this.getUnique(this.doctors.concat(res.data), "id");
      });
    }
    this.isConcateSearchData = false;
  }
  removeDuplicate(doctors: IDoctorDetailsViewModel[]): IDoctorDetailsViewModel[] {
    return doctors.filter((obj, index, arr) => {
      return (
        index ===
        arr.findIndex((obj) => {
          return JSON.stringify(obj) === JSON.stringify(obj);
        })
      );
    });
  }
  getUnique(arr: any[], comp: any) {
    const unique = arr
      .map((e: any) => e[comp])
      // store the keys of the unique objects
      .map((e: any, i: number, final: any[]) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter((e: any) => arr[e])
      .map((e: any) => arr[e]);
    return unique;
  }
  getSearch() {
    this.searchThanaId = this.location.join(",");
    this.searchSpecilities = this.specilities.join(",");
    if (this.orderBy) {
      this.orderBy = 1;
    } else {
      this.orderBy = 0;
    }
    DoctorService.getAdvanceSearch(this.distId, this.spId, this.gender, this.searchThanaId, this.fees, this.orderBy).then((res) => {
      this.doctors = res.data;
      this.alldoctors = this.doctors;
      // this.doctors = this.getUnique(this.doctors.concat(res.data), "id");
      //console.log(this.alldoctors);
    });
  }

  highestFee(max: number) {
    var j = 1;
    var rem = 0;
    if (max >= 100) {
      rem = max / 100;
    }
    this.consultationFee[0] = 0;
    for (let index = 100; index < rem * 100; index = index + 100 * j) {
      this.consultationFee[j] = index;
      j = j + 1;
    }
    this.consultationFee[j] = (Math.trunc(rem) + 1) * 100;
    // this.maxFee=(Math.trunc(rem)+1)*100;
  }
}
