

















































































































import { Vue, Component, Emit } from "vue-property-decorator";
import UniversalService from "../../../common-app/service/UniversalService";
import { IDistrict } from "../../../common-app/models/District";
import { IThana } from "../../../common-app/models/Thana";

@Component
export default class ListWidgets extends Vue {
  public availability: any[] = [
    { text: "Sunday", value: 0 },
    { text: "Monday", value: 1 },
    { text: "Tuesday", value: 2 },
    { text: "Wednesday", value: 3 },
    { text: "Thursday", value: 4 },
    { text: "Friday", value: 5 },
    { text: "Saturday", value: 6 },
  ];
  public rating: number = 3;
  public time = [8, 24];
  public fee = [0, 1000];
  public searchData: any = {};
  public districts: IDistrict[] = [];
  public thanas: IThana[] = [];
  public selectedThanas: IThana[] = [];

  @Emit("emitSearch")
  emitSearch(value: any) {
    this.searchData = value;
  }

  created() {
    UniversalService.GetAddress().then((res) => {
      this.districts = res.data.districts;
      this.thanas = res.data.thanas;
    });
  }

  onChange(id: number) {
    this.selectedThanas = this.thanas.filter((s) => s.districtId === id);
  }

  getAvailability(event: { target: HTMLInputElement }, value: number) {
    this.emitSearch({
      type: 1,
      searchKey: value,
      isChecked: event.target.checked,
    });
  }

  getFee(e: any) {
    // console.log(e);
  }

  getRating(value: number) {
    this.emitSearch({
      type: 4,
      searchKey: value,
    });
  }

  slideStopFee() {
    this.emitSearch({
      type: 3,
      searchKey: this.fee[0] + "|" + this.fee[1],
    });
  }

  slideStopTime() {
    this.emitSearch({
      type: 2,
      searchKey: this.time[0] + ":00|" + this.time[1] + ":00",
    });
  }

  getChangeThana(id: number) {
    this.emitSearch({
      type: 5,
      searchKey: id,
    });
  }

  getChangeGender(id: number) {
    this.emitSearch({
      type: 6,
      searchKey: id,
    });
  }
}
