


























































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import BookAppointment from "@/doctor-app/components/BookAppointmentNxt.vue";
// import GuestLogin from "@/doctor-app/components/GuestLogin.vue";
import { IDoctorDetailsViewModel } from "../../models/DoctorDetailsViewModel";
import DoctorChamberService from "../../service/DoctorChamberService";
import { IDoctorChamber } from "../../models/DoctorChamber";
import DoctorScheduleService from "../../../doctor-app/service/DoctorScheduleService";
import { IDoctorSchedule } from "../../../doctor-app/models/DoctorSchedule";
import DoctorAppointmentService from "../../service/DoctorAppointmentService";

@Component({
  components: {
    BookAppointment,
    // GuestLogin
  },
})
export default class DoctorCard extends Vue {
  @Prop({ default: {} }) doctor: IDoctorDetailsViewModel;
  public isShowAppoitment: boolean = false;
  public currentDate: Date = new Date();
  public doctorId: number = 0;
  public doctorSchedule: IDoctorSchedule;
  public chamberId: any = 0;
  public scheduleId: any = 0;
  public chambers: IDoctorChamber[] = [];
  public schedules: IDoctorSchedule[] = [];
  public WeeklyDate: any = [];
  public weekDay: any = [];
  public currentDay: Date;
  public chamber: number;
  public get doctorData(): IDoctorDetailsViewModel {
    return this.doctor;
  }
  created() {
    //console.log(this.doctorData);
    this.changedTimeFrom(this.currentDate);
    //console.log(this.doctor.chamberId);
    //console.log(this.doctor.id);

    DoctorAppointmentService.getAptScheduleRefresh().subscribe((res) => {
      if (res) {
        this.onChangeSchedule();
      }
    });

    this.getAllSchedules();

    this.chamberId = this.doctor.chamberId;
  }

  getAllSchedules() {
    DoctorScheduleService.GetScheduleListById(this.doctor.id, this.doctor.chamberId, this.currentDate).then((res) => {
      //console.log(res);
      if (res.data) {
        this.schedules = [];
        this.schedules = res.data || [];
        //console.log(this.schedules);
        // alert();
        //this.$store.dispatch("addChamberList", res.data.result);
      }
    });
  }
  closeModal(value: any) {
    this.isShowAppoitment = value;
  }
  book(doctorId: number, selecteddate: any, chamber: number, scheduleId: number) {
    this.doctorId = doctorId;
    this.currentDay = selecteddate;
    this.chamberId = chamber;
    this.isShowAppoitment = true;
    this.scheduleId = scheduleId;
  }
  formatter(date: any) {
    var last = new Date(date.getTime());
    var day = last.getDate() - 1;
    var month = last.getMonth() + 1;
    var year = last.getFullYear();
    var fulld = "";
    if (day < 10) {
      fulld = Number(year) + "-" + Number(month) + "-0" + Number(day);
    } else if (month < 10) {
      fulld = Number(year) + "-0" + Number(month) + "-" + Number(day);
    } else if (month < 10 && day < 10) {
      fulld = Number(year) + "-0" + Number(month) + "-0" + Number(day);
    } else {
      fulld = Number(year) + "-" + Number(month) + "-" + Number(day);
    }

    return fulld;
  }

  onChangeSchedule() {
    DoctorScheduleService.GetScheduleListById(this.doctor.id, this.chamberId, this.currentDate).then((res) => {
      this.schedules = [];
      if (res.data) {
        this.schedules = res.data || [];
        //console.log(res.data);
      }
    });
  }

  onChange(event: any) {
    this.chamberId = event.target.value;
    this.onChangeSchedule();
  }

  onchangeCalendar() {
    this.$nextTick(() => this.onChangeSchedule());
    this.$nextTick(() => this.changedTimeFrom(this.currentDate));
    // this.onChangeSchedule();
    // this.changedTimeFrom(this.currentDate);
  }

  findSchedule(value: any) {
    if (value == 1) {
      var increment = new Date(this.currentDate.getTime() + 1 * 24 * 60 * 60 * 1000);
      this.currentDate = increment;
    } else if (value == -1) {
      var decrement = new Date(this.currentDate.getTime() - 1 * 24 * 60 * 60 * 1000);
      this.currentDate = decrement;
    } else if (value == 7) {
      increment = new Date(this.currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
      this.currentDate = increment;
    } else if (value == -7) {
      increment = new Date(this.currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      this.currentDate = increment;
    } else if (value == 30) {
      increment = new Date(this.currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);
      this.currentDate = increment;
    }
    this.onChangeSchedule();
    this.changedTimeFrom(this.currentDate);
  }

  getScheduleId(value: any, value2: any) {
    this.scheduleId = value;
    this.currentDay = value2;
  }

  changedTimeFrom(value: any) {
    this.WeeklyDate = [];
    this.weekDay = [];
    var days = 7;
    for (var i = 0; i < days; i++) {
      if (i == 0) {
        i = 1;
        days += 1;
      }
      var date = value;
      var last = new Date(date.getTime() + i * 24 * 60 * 60 * 1000);
      // console.log(last);
      var day = last.getDate() - 1;
      var month = last.getMonth() + 1;
      var year = last.getFullYear();
      if (day == 0) {
        last = new Date(date.getTime() + (i - 1) * 24 * 60 * 60 * 1000);
        day = last.getDate();
        month = last.getMonth() + 1;
        year = last.getFullYear();
      }

      if (day < 10 && month > 9) {
        const fulld = Number(year) + "-" + Number(month) + "-0" + Number(day);
        this.WeeklyDate.push(fulld);
      } else if (month < 10 && day > 9) {
        const fulld = Number(year) + "-0" + Number(month) + "-" + Number(day);
        this.WeeklyDate.push(fulld);
      } else if (month < 10 && day < 10) {
        const fulld = Number(year) + "-0" + Number(month) + "-0" + Number(day);
        this.WeeklyDate.push(fulld);
      } else {
        const fulld = Number(year) + "-" + Number(month) + "-" + Number(day);
        this.WeeklyDate.push(fulld);
      }
      var weekdays = ["SAT", "SUN", "MON", "TUE", "WED", "THU", "FRI"];

      var day2 = weekdays[last.getDay()];
      if (day == 30 || day == 31 || day == 28 || day == 29) {
        last = new Date(date.getTime() + i * 24 * 60 * 60 * 1000);
        day2 = weekdays[last.getDay()];
      }
      this.weekDay.push(day2);
    }
    //console.log(this.WeeklyDate);
    //console.log(this.weekDay);
  }

  timeFrom() {
    var days = 7;
    for (var i = 0; i < days; i++) {
      if (i == 0) {
        i = 1;
        days += 1;
      }
      var date = new Date();
      var last = new Date(date.getTime() + i * 24 * 60 * 60 * 1000);
      //console.log(last);

      var day = last.getDate() - 1;
      var month = last.getMonth() + 1;
      var year = last.getFullYear();

      if (day < 10) {
        const fulld = Number(year) + "-" + Number(month) + "-0" + Number(day);
        this.WeeklyDate.push(fulld);
      } else if (month < 10) {
        const fulld = Number(year) + "-0" + Number(month) + "-" + Number(day);
        this.WeeklyDate.push(fulld);
      } else if (month < 10 && day < 10) {
        const fulld = Number(year) + "-0" + Number(month) + "-0" + Number(day);
        this.WeeklyDate.push(fulld);
      } else {
        const fulld = Number(year) + "-" + Number(month) + "-" + Number(day);
        this.WeeklyDate.push(fulld);
      }

      var weekdays = ["SAT", "SUN", "MON", "TUE", "WED", "THU", "FRI"];
      var day2 = weekdays[last.getDay()];
      // alert(last.getDay());
      this.weekDay.push(day2);
      //alert(day2);
    }
    //console.log(this.weekDay);
    //console.log(this.WeeklyDate);
  }

  formatDate(value: any) {
    var fulld = "";
    var last = new Date(value);
    var day = last.getDate();
    var month = last.getMonth() + 1;
    var year = last.getFullYear();

    if (day < 10) {
      fulld = Number(year) + "-" + Number(month) + "-0" + Number(day);
    } else if (month < 10) {
      fulld = Number(year) + "-0" + Number(month) + "-" + Number(day);
      this.WeeklyDate.push(fulld);
    } else if (month < 10 && day < 10) {
      fulld = Number(year) + "-0" + Number(month) + "-0" + Number(day);
      this.WeeklyDate.push(fulld);
    } else {
      fulld = Number(year) + "-" + Number(month) + "-" + Number(day);
      this.WeeklyDate.push(fulld);
    }
    //console.log(fulld);
    return fulld;
  }
}
