























































































































































































































































































































































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { DayOfWeek } from "../../helper/Enums";
import { defer } from "rxjs";
import DoctorService from "../service/DoctorService";
import DoctorAppointmentService from "../service/DoctorAppointmentService";
import DoctorScheduleService from "@/doctor-app/service/DoctorScheduleService";
import UniversalService from "../../common-app/service/UniversalService";
import { IDoctorChamber } from "../models/DoctorChamber";
import { IDoctorChamberSchedule } from "../models/DoctorChamberSchedule";
import { IDoctorAppointment } from "../models/DoctorAppointment";
import { IDoctorSchedule } from "@/doctor-app/models/DoctorSchedule";
import GuestLogin from "@/doctor-app/components/GuestLogin.vue";
import AuthUser from "../../global-config/AuthUser";
import AlertService from "@/common-app/service/AlertService";
import AuthService from "../../website/services/AuthService";
import { RegViewModel } from "../../website/models/RegViewModel";
import TermsConditions from "./TermsConditions.vue";
@Component({
  components: {
    GuestLogin,
    TermsConditions,
  },
})
export default class BookAppointment extends Vue {
  @Prop({ default: 0, required: true }) doctorId!: number;
  @Prop({ required: true }) currentDay: Date;
  @Prop({ default: 0, required: true }) chamber: number;
  @Prop({ default: 0, required: true }) currentscheduleId: number;
  public currentDate: Date = new Date();
  public isShow: boolean = false;
  public DayOfWeek = DayOfWeek;
  public chambers: IDoctorChamber[] = [];
  public schedules: IDoctorSchedule[] = [];
  public allAvaliableSchedules: IDoctorSchedule[] = [];
  public chamberScheduleList: IDoctorChamberSchedule[] = [];
  public scheduleList: IDoctorChamberSchedule[] = [];
  public chamberSchedule: IDoctorChamberSchedule = {} as IDoctorChamberSchedule;
  public doctorAppoitment: IDoctorAppointment = {} as IDoctorAppointment;
  public currentTab = "#step1";
  public enableStep2: boolean = false;
  public enableStep3: boolean = false;
  public showVerificationTab: boolean = false;
  public showConfirmationTab: boolean = false;
  public verificationCode: string = "";
  public currentIndex: number = -1;
  public submited: boolean = false;
  public isConfirmed: boolean = false;
  public enableDatepicker: boolean = false;
  public isLoggedIn: boolean = false;
  public isShowGuestLogin: boolean = true;
  public sendMessageCount = 1;
  public chamberId: number;
  public scheduleId: number;
  public startTime: any = "";
  public user = AuthUser.getAuthUser();
  public appointmentWithGuest: boolean = false;
  public user2: RegViewModel = {} as RegViewModel;
  public errorMessage: string = "";
  public mindate: Date = new Date();
  public showTermsConditions: boolean = false;
  created() {
    const dict = {
      custom: {
        ChamberName: {
          required: "The chamber name is required.",
        },
        patientName: {
          required: "Patient name is required.",
        },
        Phone: {
          required: "Phone number is required.",
          numeric: "phone number can only contain numeric value.",
          min: "Phone number should be minimum 11 charecter.",
          max: "Phone number cab be maximum 11 charecter.",
        },
        dob: {
          required: "Date of birth is required",
          beforeCurrentDate: "Date of birth must be before current date.",
        },
      },
    };
    this.$validator.localize("en", dict);
    this.isLoggedIn = AuthUser.isLoggednIn() || false;
    DoctorService.GetChambers(this.doctorId).then((res) => {
      this.chambers = res.data;
      // this.chambers = this.chambers.filter((e) => e.isVirtual !== true);
    });
    if (this.isLoggedIn) {
      this.isShow = true;
    }
    // if (this.isLoggedIn) {
    //   this.doctorAppoitment.patientName = this.user.firstName + " " + this.user.lastName;
    //   this.doctorAppoitment.phone = this.user.phone;
    // }
    // alert();
  }
  mounted() {
    this.currentIndex = this.currentscheduleId;
    var dd = new Date(this.currentDay);
    this.chamberId = this.chamber;
    this.doctorAppoitment.chamberId = this.chamberId;
    this.scheduleId = this.currentscheduleId;
    this.currentDate = new Date(this.currentDay);
    DoctorScheduleService.GetScheduleListByDate(this.chamberId, this.currentDate).then((res) => {
      if (res.data.result) {
        this.schedules = res.data.result;
        this.allAvaliableSchedules = this.schedules.filter((s) => s.isBooked == false) || [];
      }
    });
    if (this.isLoggedIn) {
      this.doctorAppoitment.patientName = this.user.firstName + " " + this.user.lastName;
      this.doctorAppoitment.phone = this.user.phone;
    }
  }
  @Emit("close")
  close() {
    this.isShow = false;
    return this.isShow;
  }
  findSchedule(value: number) {
    this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() + value));
    let day = this.currentDate.getDay();
    this.scheduleList = this.chamberScheduleList.filter((s) => s.day == day) || [];
    this.onChangeSchedule();
  }
  // onChange(event: any) {
  //   DoctorService.GetChamberSchedule(Number(event.target.value)).then((res) => {
  //     this.scheduleList = [];
  //     this.currentIndex = -1;
  //     this.chamberScheduleList = (res.data as IDoctorChamberSchedule[]).map((item) => Object.assign({}, item, { isClick: false }));
  //   });
  // }
  onChangeSchedule() {
    DoctorScheduleService.GetScheduleListByDate(this.chamberId, this.currentDate).then((res) => {
      this.schedules = [];
      this.allAvaliableSchedules = [];
      if (res.data.result) {
        this.schedules = res.data.result;
        this.allAvaliableSchedules = this.schedules.filter((s) => s.isBooked == false) || [];
      }
    });
  }

  onchangeCalendar(e: any) {
    this.$nextTick(() => this.onChangeSchedule());
  }
  onChange(event: any) {
    this.chamberId = event.target.value;
    this.onChangeSchedule();
  }
  sendCode() {
    DoctorAppointmentService.reSendCode(this.doctorAppoitment.id).then((res) => {
      if (this.sendMessageCount <= 3) {
        if (res.data) {
          alert("code send success");
        } else {
          alert("code send error");
        }
      }
      this.sendMessageCount++;
    });
  }
  add(schedule: IDoctorChamberSchedule, index: number) {
    this.currentIndex = index;
    //alert(this.currentIndex);
    this.chamberSchedule = schedule;
    // Vue.set(
    //   this.scheduleList,
    //   index,
    //   Object.assign(schedule, { isClick: true })
    // );
    // if (schedule.isClick) {
    //   this.scheduleList.splice(
    //     index,
    //     1,
    //     Object.assign({}, schedule, { isClick: false })
    //   );
    //   let newSchedule = this.doctorAppoitment.doctorAppointmentDetails.filter(
    //     s => s.scheduleId != schedule.id
    //   );
    //   this.doctorAppoitment.doctorAppointmentDetails = newSchedule;
    // } else {
    //   this.scheduleList.splice(
    //     index,
    //     1,
    //     Object.assign({}, schedule, { isClick: true })
    //   );
    //   let data = {
    //     startTime: schedule.startTime,
    //     endTime: schedule.endTime,
    //     id: 0,
    //     appointmentId: 0,
    //     scheduleId: schedule.id
    //   };
    //   this.doctorAppoitment.doctorAppointmentDetails.push(
    //     Object.assign({}, data)
    //   );
    // }
    // this.doctorAppoitment = Object.assign({}, this.doctorAppoitment);
  }
  getScheduleId(value: any, value2: any, index: number) {
    this.scheduleId = value;
    // this.currentIndex = value;
    this.startTime = value2;
    this.currentIndex = value;
    //alert(this.scheduleId);
  }
  goToVerification(scope: string) {
    this.$validator.validateAll(scope).then((valid) => {
      if (valid) {
        //  debugger;
        if (scope == "form-1" && this.isLoggedIn == false) {
          //this.submited = true;
          // UniversalService.sendSms(this.doctorAppoitment.phone.toString()).then((res) => {
          //   // this.doctorAppoitment.verificationCode = res.data;
          //   this.enableStep2 = true;
          //   this.showVerificationTab = true;
          //   this.submited = false;
          // });
          this.submited = true;
          this.user2.phone = this.doctorAppoitment.phone;
          this.user2.firstName = this.doctorAppoitment.patientName;
          this.user2.password = "bddot";
          // console.log(this.user2);
          AuthService.registration(this.user2)
            .then((response) => {
              let result = response.data as any;
              if (result.status) {
                //console.log(result.result.securityVerification.code);
                this.enableStep2 = true;
                this.showVerificationTab = true;
                this.submited = false;
                this.$store.dispatch("addVerify", {
                  userId: result.result.user.id,
                  verifycode: result.result.securityVerification.code,
                });
                // console.log(result.result.user.id)
                // console.log(result.result.securityVerification.code)
                //this.$router.push({ path: "registration-verify" });
              } else {
                this.submited = false;
                if (result.messageType == "unique") {
                  this.$validator.errors.add({
                    field: "phone",
                    msg: result.message,
                  });
                } else {
                  //AlertService.sendMessage({ status: false, message:result.message});
                }
              }
            })
            .catch((error) => {
              this.submited = false;
            });
        } else {
          // if (this.doctorAppoitment.verificationCode.toString() !== this.verificationCode) {
          //   alert("code not valid");
          //   this.submited = false;
          //   return;
          // }
          //this.goToCompletion();
          // var givendate=new Date();
          // alert(this.currentDate);
          // alert(givendate.getHours()+':'+givendate.getMinutes());
          // return;
          var today = new Date();
          if (today > this.currentDate) {
            AlertService.sendMessage("Invalid Date");
          }
          this.submited = true;
          this.doctorAppoitment.chamberSchedulesId = this.scheduleId;
          DoctorAppointmentService.insert(
            Object.assign(this.doctorAppoitment, {
              appointmentDate: this.currentDate,
              doctorId: this.doctorId,
            })
          ).then((res) => {
            if (res.data.status) {
              this.submited = false;
              this.doctorAppoitment = res.data.result;
              this.enableStep2 = true;
              this.showVerificationTab = true;
              this.submited = false;
              // AlertService.sendMessage({ status: true, message: "appointment success" });
              this.goToCompletion();
              DoctorAppointmentService.refreshAptSchedule(true);
            } else {
              //console.log(res.data);
              AlertService.sendMessage({ status: false, message: res.data.message });
              this.submited = false;
            }
          });
          // UniversalService.sendSms(this.doctorAppoitment.phone.toString()).then(
          //   res => {
          //     this.doctorAppoitment.verificationCode = res.data;
          //     this.enableStep2 = true;
          //     this.showVerificationTab = true;
          //     this.submited = false;
          //   }
          // );
        }
        // else {
        //   if (this.doctorAppoitment.code.toString() !== this.verificationCode) {
        //     alert("code not valid");
        //     this.submited = false;
        //     return;
        //   }
        //   this.submited = true;
        //   this.doctorAppoitment.code = this.verificationCode;
        //   DoctorAppointmentService.varify(this.doctorAppoitment).then(res => {
        //     this.submited = false;
        //     alert("appointment success");
        //     this.goToCompletion();
        //   });
        // }
      }
    });
  }
  goToCompletion() {
    this.enableStep3 = true;
    this.enableStep2 = false;
    this.showConfirmationTab = true;
    this.showVerificationTab = false;
  }
  showDatePicker() {
    this.enableDatepicker = !this.enableDatepicker;
  }
  closeGuestLogin() {
    this.isShowGuestLogin = false;
    // this.isLoggedIn = true;
    // this.isShow = false;
    //this.close();

    if (AuthUser.isLoggednIn()) {
      this.isShowGuestLogin = false;
      this.isShow = true;
      this.isLoggedIn = true;
      this.user = AuthUser.getAuthUser();
      this.doctorAppoitment.patientName = this.user.firstName + " " + this.user.lastName;
      this.doctorAppoitment.phone = this.user.phone;
      DoctorScheduleService.GetScheduleListByDate(this.chamberId, this.currentDate).then((res) => {
        if (res.data.result) {
          this.schedules = res.data.result;
          this.allAvaliableSchedules = this.schedules.filter((s) => s.isBooked == false) || [];
        }
      });
    } else {
      this.close();
    }
  }
  loginWithGuest() {
    this.isShowGuestLogin = false;
    this.isShow = true;
    this.appointmentWithGuest = true;
    this.isShowGuestLogin = false;
    if (AuthUser.isLoggednIn()) {
      this.user = AuthUser.getAuthUser();
      this.doctorAppoitment.patientName = this.user.firstName + " " + this.user.lastName;
      this.doctorAppoitment.phone = this.user.phone;
    }
  }
  validate() {
    if (this.doctorAppoitment.patientName.length <= 0) {
      this.errorMessage = "Patient name is required.w";
    } else {
      this.doctorAppoitment.patientName = this.doctorAppoitment.patientName;
      this.errorMessage = "";
    }
  }
  closeTermsConditions() {
    this.showTermsConditions = false;
  }
}
